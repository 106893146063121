import IntroJs from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
// introjs还提供了多种主题，可以通过以下方式引入
//import 'intro.js/themes/introjs-modern.css'
export const intro = IntroJs;
const introOption= {
    // 这里是更换成中文（默认英文）
    prevLabel: "上一步",
    nextLabel: "下一步",
    // skipLabel: "X",
    doneLabel: "完成",
    /* 引导说明框相对高亮说明区域的位置 */
    tooltipPosition: "bottom",
    // /* 引导说明文本框的样式 */
    //tooltipClass: "intro-tooltip",
    // /* 说明高亮区域的样式 */
    // highlightClass: "intro-highlight",
    /* 是否使用键盘Esc退出 */
    exitOnEsc: true,
    /* 是否允许点击空白处退出 */
    exitOnOverlayClick: false,
    /* 提示边框的padding */
    helperElementPadding: 2,
    /* 是否显示说明的数据步骤 */
    showStepNumbers: false,
    /* 是否允许键盘来操作 */
    keyboardNavigation: true,
    /* 是否按键来操作 */
    showButtons: true,
    /* 是否使用点显示进度 */
    showBullets: true,
    /* 是否显示进度条 */
    showProgress: false,
    /* 是否滑动到高亮的区域 */
    scrollToElement: true,
    /* 遮罩层的透明度 */
    overlayOpacity: 0.7,
    /* 当位置选择自动的时候，位置排列的优先级 */
    positionPrecedence: ["bottom", "top", "right", "left"],
    /* 是否禁止与元素的相互关联 */
    disableInteraction: false,
    /* 是否在第一步隐藏上一步 */
    // hidePrev: true,
    /* 是否在最后一步隐藏下一步 */
    // hideNext: true,
    /* steps步骤，可以写个工具类保存起来 */
    steps: [],
  }
 
  const getIntro = (options) => {
    return new IntroJs().setOptions(
      Object.assign(JSON.parse(JSON.stringify(introOption)), options)
    );
  };
  export const homeIntro = getIntro({
    //disableInteraction: true,
    //tooltipClass: "intro-no-skip",
    steps: [
      {
        element: "#itemSelect",
        title: '地区经济现状',
        intro: "GDP、年末常驻人口、内需、供给等指标展示地区经济现状。选择指标，地图显示下级地区此指标数据以及右侧显示各地区排名",
        position: "bottom",
      },
      {
        element: "#ecoVitality",
        title: '地区经济活力',
        intro: "包含“地区简介”，“经济活力”，之间可以进行切换",
        position: "right",
      },
      
      {
        element: "#regionSore",
        title: '地区排名',
        intro: "国内同等级地区的排名，选择地区显示近几年的历史趋势",
        position: "right",
      },
      {
        element: "#showNews",
        title: '地区舆情',
        intro: "滚动显示本地区近期的经济舆情，鼠标点击经济舆情标题显示详细信息",
        position: "top"
      }
      
    ]
  });
  export const ecoDevIntro = getIntro({
    //disableInteraction: true,
    //tooltipClass: "intro-no-skip",
    steps: [
      {
        element: "#theme",
        title: '主题列表',
        intro: "选择不同的主题，从多方面以图表、列表等形式展现区域经济发展趋势",
        position: "bottom",
      },
      {
        element: "#download",
        title: '下载',
        intro: "单击“下载”图标，左侧栏显示要下载的主题列表，选择需要下载的主题后单击“下载”将导出本地区相应的指标数据",
        position: "right",
      },
      {
        element: "#difference",
        title: '地区比较',
        intro: "单击“地区比较”图标，左侧栏默认显示相近的三个城市。可以选择时间以此时间计算相近对比的城市，或手动修改城市。单击“开始对比”，进入地区比较的页面",
        position: "top"
      },
      {
        element: "#myTheme",
        title: '我的主题',
        intro: "单击“我的主题”图标，左侧栏显示用户自定义的区域态势主题。选择主题单击“确认”进入我的主题页面。单击“创建”进入自定义区域态势页面",
        position: "top"
      },
    ]
  });
  